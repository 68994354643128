<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('dae_subsidy.distribution') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <iq-card>
                                                <template v-slot:body>
                                                    <div class="row">
                                                        <div class="col-sm-6 col-md-6">
                                                            <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="5"
                                                                    label-for="division_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    {{$t('globalTrans.division')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.division_id"
                                                                    :options="divisionList"
                                                                    id="division_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    disabled
                                                                    >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                    </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-sm-6 col-md-6">
                                                            <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="5"
                                                                    label-for="district_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    {{$t('globalTrans.district')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.district_id"
                                                                    :options="districtList"
                                                                    id="district_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    disabled
                                                                    >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-sm-6 col-md-6">
                                                            <ValidationProvider name="Upazilla" vid="upazilla_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="5"
                                                                    label-for="upazilla_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    {{$t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.upazilla_id"
                                                                    :options="upazilaList"
                                                                    id="upazilla_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    disabled
                                                                    >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-sm-6 col-md-6">
                                                            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="5"
                                                                    label-for="fiscal_year_id"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    {{$t('dae_subsidy.fiscal_year')}} <span class="text-danger">*</span>
                                                                </template>
                                                                    <b-form-select
                                                                        plain
                                                                        v-model="formData.fiscal_year_id"
                                                                        :options="fiscalYearList"
                                                                        id="fiscal_year"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                        disabled
                                                                    >
                                                                        <template v-slot:first>
                                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                        </template>
                                                                    </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-sm-6 col-md-6">
                                                            <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="5"
                                                                    label-for="circular_type"
                                                                    slot-scope="{ valid, errors }"
                                                                >
                                                                <template v-slot:label>
                                                                    {{$t('dae_subsidy.circular_ty')}} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="formData.circular_type"
                                                                    :options="circularTypeList"
                                                                    id="circular_type"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    disabled
                                                                    >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                    </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-sm-6 col-md-6">
                                                            <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                                                                <b-form-group
                                                                    class="row"
                                                                    label-cols-sm="5"
                                                                    label-for="circular_id"
                                                                    slot-scope="{ valid, errors }">
                                                                    <template v-slot:label>
                                                                    {{ $t('dae_subsidy.circular') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-select
                                                                    plain
                                                                    v-model="formData.circular_id"
                                                                    :options="circularList"
                                                                    @change="getApplication"
                                                                    id="circular_id"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                    disabled
                                                                    >
                                                                    <template v-slot:first>
                                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                    </template>
                                                                    </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </div>
                                                    </div>
                                                </template>
                                            </iq-card>
                                        </div>
                                        <div class="col-sm-12 col-md-12" v-if="is_subsidy">
                                            <iq-card>
                                                <template v-slot:body>
                                                    <template>
                                                        <div style="font-size:12px; background-color: #337982;">
                                                            <h5 class="text-white text-center"> {{ $t('dae_subsidy.stock_list') }}</h5>
                                                        </div>
                                                    </template>
                                                    <b-table-simple striped bordered small class="mt-2">
                                                        <b-tr>
                                                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                                            <b-th>{{ $t('dae_subsidy.items_name') }}</b-th>
                                                            <b-th width="100px">{{ $t('dae_subsidy.quantity') }}</b-th>
                                                        </b-tr>
                                                        <b-tr v-for="(stock,index) in formData.centralStockQtyInsList" :key="index">
                                                            <b-td>{{ $n(index+1) }}</b-td>
                                                            <b-td>{{ getInstumentName(stock.item_id) }}</b-td>
                                                            <b-td><input class="form-control" type="text" v-model="stock.quantity" readonly/></b-td>
                                                        </b-tr>
                                                    </b-table-simple>
                                                </template>
                                            </iq-card>
                                        </div>
                                        <div class="col-sm-12 col-md-12">
                                            <p class="text-danger" v-if="is_warning"> {{'Distribution quantity and stock quantity not match or distribution is bigger than stock'}}</p>
                                        </div>
                                        <div class="col-sm-12 col-md-12" v-if="is_subsidy">
                                             <iq-card>
                                                <template v-slot:body>
                                                    <template>
                                                        <div style="font-size:12px; background-color: #337982;">
                                                            <h5 class="text-white text-center"> {{ $t('dae_subsidy.applicantlist') }}</h5>
                                                        </div>
                                                    </template>
                                                    <b-table-simple striped bordered small class="mt-2">
                                                        <b-tr>
                                                            <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.applicant') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.mobile_no') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.nid') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.items_name') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.application_date') }}</b-th>
                                                            <b-th class="text-center">{{ $t('dae_subsidy.quantity') }}</b-th>
                                                            <b-th class="text-center">{{ $t('globalTrans.approve') }}</b-th>
                                                        </b-tr>
                                                        <b-tr v-for="(applicant,index) in formData.tmpApplicantList" :key="index">
                                                            <b-td>{{ $n(index+1) }}</b-td>
                                                            <b-td>{{ ($i18n.locale === 'bn') ? applicant.name_bn : applicant.name }}</b-td>
                                                            <b-td>{{ '0' + $n(applicant.mobile_no, { useGrouping: false }) }}</b-td>
                                                            <b-td>{{ $n(applicant.nid_no, { useGrouping: false }) }}</b-td>
                                                            <b-td>{{ ($i18n.locale === 'bn') ? applicant.instrument_name_bn : applicant.instrument_name }}</b-td>
                                                            <b-td>{{ applicant.created_at | dateFormat}}</b-td>
                                                            <b-td><input class="form-control" type="text" value="1" readonly/></b-td>
                                                            <b-td class="text-center"><input type="checkbox" v-model="applicant.is_checked"/></b-td>
                                                        </b-tr>
                                                    </b-table-simple>
                                                </template>
                                            </iq-card>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6">
                                            <ValidationProvider name="Distribution Date" vid="distribution_date" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="5"
                                                    label-for="distribution_date"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('dae_subsidy.distribution_data') }} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    class="fromDate"
                                                    id="distribution_date"
                                                    v-model="formData.distribution_date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :placeholder="$t('dae_subsidy.select_date')"
                                                >
                                                </b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6">
                                            <ValidationProvider name="Distribution Place (En)" vid="place" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="5"
                                                    label-for="place"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('dae_subsidy.place_en') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="place"
                                                        v-model="formData.place"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-sm-6 col-md-6">
                                            <ValidationProvider name="Distribution Place (Bn)" vid="place_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="5"
                                                    label-for="place_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('dae_subsidy.place_bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="place"
                                                        v-model="formData.place_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-9">
                                            <p style="font-weight:600">[{{ $t('dae_subsidy.distributed_note') }}]</p>
                                        </div>
                                        <div class="col-sm-6 col-md-3">
                                            <div class="text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-6')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { distributionStore, getApplicationDataApi } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['item'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        const tmp = this.getformDataData()
        const circular = this.$store.state.incentiveGrant.commonObj.circularList.find(item => item.value === tmp.circular_id)
        tmp.circular_type = circular.circular_type
        this.formData = Object.assign({}, this.formData, tmp)
    },
    data () {
        return {
            grandTotal: 0,
            loading: false,
            isStock: false,
            fiscalYear: '',
            saveBtnName: this.$t('globalTrans.submit'),
            formData: {
                fiscal_year_id: 0,
                circular_type: 0,
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                circular_id: 0,
                place: '',
                place_bn: '',
                distribution_date: '',
                tmpApplicantList: [],
                applicant_details: [],
                centralStockQtyInsList: [],
                stockQtyCropMeterial: []
            },
            circularList: [],
            districtList: [],
            upazilaList: [],
            is_subsidy: false,
            is_incentiveMeretial: false,
            is_warning: false,
            is_item: true,
            is_stock: true,
            is_permission: true,
            validationResult: {
                success: true,
                message: ''
            },
            upzAllocationAmount: 0,
            allocationAmount: 0,
            accountTypeList: [
              { value: 1, text_en: 'Mobile Banking', text_bn: 'মোবাইল ব্যাংকিং' },
              { value: 2, text_en: 'Bank', text_bn: 'ব্যাংক' }
            ],
            totalRowItem: {
                crops: {},
                materials: {}
            }
        }
    },
    watch: {
        'formData.circular_type': function (newValue, oldVal) {
            this.circularList = this.getCircularName()
            if (newValue === 1) {
                this.formData.applicant_details = []
                this.is_subsidy = true
                this.is_incentiveMeretial = false
            }
            if (newValue !== 1) {
                if (newValue !== oldVal) {
                    this.formData.applicant_details = []
                }
                this.formData.tmpApplicantList = []
                this.is_subsidy = false
                this.is_incentiveMeretial = true
            }
        },
        'formData.fiscal_year_id': function () {
            this.circularList = this.getCircularName()
        },
        'formData.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'formData.district_id': function (newVal, oldVal) {
            this.upazilaList = this.getUpazilaList(newVal)
        }
    },
    mounted () {
        core.index()
        flatpickr('.fromDate', {})
        this.getApplication()
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        },
        divisionList () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        circularTypeList () {
            const data = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Subsidy' : 'ভর্তুকি' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Incentive' : 'প্রণোদনা' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Rehabilitation' : 'পুনর্বাসন' }
            ]
            return data
        },
        typeList () {
            const data = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Instrument' : 'যন্ত্র' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Crop' : 'শস্য' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Material' : 'উপাদান' }
            ]
            return data
        },
        fiscalYearList () {
            return this.$store.state.commonObj.fiscalYearList.map(item => {
              const customItem = { text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en }
              return Object.assign({}, item, customItem)
            })
        }
    },
    methods: {
        async getApplication () {
            if (this.formData.circular_id !== 0) {
                this.loading = true
                const params = {
                    fiscal_year_id: this.formData.fiscal_year_id,
                    division_id: this.formData.division_id,
                    district_id: this.formData.district_id,
                    upazilla_id: this.formData.upazilla_id,
                    circular_type: this.formData.circular_type,
                    circular_id: this.formData.circular_id
                }
                await RestApi.getData(incentiveGrantServiceBaseUrl, getApplicationDataApi, params)
                .then(response => {
                    if (response.success) {
                        if (this.formData.circular_type === 1) {
                            this.formData.centralStockQtyInsList = response.data.centralStockQtyInsList
                            this.formData.tmpApplicantList = response.data.application.map(item => {
                                const check = { is_checked: true }
                                return Object.assign({}, item, check)
                            })
                        }
                    } else {
                        this.$toast.error({
                            title: this.$t('globalTrans.error'),
                            message: response.message,
                            color: '#fff'
                        })
                    }
                })
                this.loading = false
            } else {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: 'Please , Select valid circular',
                    color: '#fff'
                })
                this.formData.tmpApplicantList = []
                this.formData.applicant_details = []
                this.$bvModal.hide('modal-4')
            }
        },
        getformDataData () {
          return JSON.parse(JSON.stringify(this.item))
        },
        validationCheck () {
            if (this.formData.circular_type === 1) {
                const isCheckCount = this.formData.tmpApplicantList.filter(item => item.is_checked === true)
                if (isCheckCount.length !== 0) {
                    let tm = []
                    this.formData.tmpApplicantList.forEach(appItem => {
                        if (appItem.is_checked === true) {
                            const stockItem = this.formData.centralStockQtyInsList.find(stockItem => stockItem.item_id === parseInt(appItem.instrument_id))
                            if (typeof stockItem === 'undefined') {
                                this.is_item = false
                            } else {
                                if (tm.length === 0) {
                                    const dd = Object.assign(appItem, { quantity: 1 })
                                    tm.push(dd)
                                } else {
                                    const ntm = tm.find(itm => parseInt(itm.instrument_id) === parseInt(appItem.instrument_id))
                                    if (typeof ntm === 'undefined') {
                                        const rr = Object.assign(appItem, { quantity: 1 })
                                        tm.push(rr)
                                    } else {
                                        const datas = []
                                        tm.forEach(i => {
                                            if (parseInt(i.instrument_id) === parseInt(ntm.instrument_id)) {
                                                const quantity = parseInt(i.quantity) + 1
                                                datas.push(Object.assign(i, { quantity: quantity }))
                                            }
                                        })
                                        tm = datas
                                    }
                                }
                            }
                        }
                    })
                    if (tm.length > 0) {
                        tm.map(item => {
                            const stockItm = this.formData.centralStockQtyInsList.find(stock => stock.item_id === item.instrument_id)
                            if (item.quantity > stockItm.quantity) {
                                this.is_stock = false
                            }
                        })
                    }
                } else {
                    this.is_permission = false
                }
            }
        },
        async saveData () {
          this.validationResult.success = true
          this.validationResult.message = ''
            this.validationCheck()
            if (!this.validationResult.success) {
                this.displayError(this.validationResult.message)
                return
            }
            if (this.is_item === true && this.is_stock === true && this.is_permission === true) {
                this.loading = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                let result = null
                const loadingState = { loading: false, listReload: false }
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, distributionStore, this.formData)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.loading = false
                if (result.success) {
                    this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                    this.$toast.success({
                        title: this.$t('globalTrans.success'),
                        message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                  this.$bvModal.hide('modal-6')
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: result.message,
                        color: '#fff'
                    })
                }
            } else {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: 'Item not found in stock or bigger than stock',
                    color: '#fff'
                })
                this.is_item = true
                this.is_stock = true
                this.is_permission = true
            }
        },
        displayError (message) {
            this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: message,
                color: '#fff'
            })
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getCircularName () {
            const circularList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.fiscal_year_id === this.formData.fiscal_year_id && item.type === this.formData.circular_type)
            return circularList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        getInstumentName (itemId) {
            const insList = this.$store.state.incentiveGrant.commonObj.instrumentList.find(item => item.value === itemId)
            if (this.$i18n.locale === 'bn') {
                return insList.text_bn
            } else {
                return insList.text_en
            }
        }
    }
}
</script>
